import React from 'react';
import devSkillsDesktop from '../resources/img/devskills-desktop.png'
import devNewsDesktop from '../resources/img/devnews-desktop.png'

export default function Projects () {
    return (
      <div className="content">
        <h1>Here are some recent projects I've worked on.</h1>
          <div className="project">
          <div className="project-content">
            <h3>DevSkills</h3>
            <p>
              DevSkills is my attempt at replicating a physical goal setting and skill development exercise.
            </p>
            <p>
              <a href="https://devskills.cameocodes.com" target="_blank" rel="noopener noreferrer" className="hvr-grow">See it live here.</a>
            </p>
          </div>
            <div>
              <a href={devSkillsDesktop} target="_blank" rel="noopener noreferrer"><img src={devSkillsDesktop} className="project-img" alt="Screenshot of Dev Skills drag and drop function." /></a>
            </div>
          </div>
            
          <div className="project">
          <div className="project-content">
            <h3>DevNews</h3>
            <p>
            DevNews is a news aggregator that takes the top stories from HackerNews, various tech Medium publications and a few subreddits so I don't have to switch between tabs on my mobile during my commute to work.
            </p>
            <p>
            <a href="https://devnews.cameocodes.com" target="_blank" rel="noopener noreferrer" className="hvr-grow">See it live here.</a>
            </p>
          </div>
            <div>
              <a href={devNewsDesktop} target="_blank" rel="noopener noreferrer"><img src={devNewsDesktop} className="project-img" alt="Screenshot of DevNews list of articles." /></a>
            </div>
          </div>
      </div>
    );
}