import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import Main from './components/Main'
import Navigation from './components/Navigation'
import Projects from './components/Projects'
import Contact from './components/Contact'
import Footer from './components/Footer'

class App extends Component {
  render() {
    return (
      <div className="app">
        <Router>
          <div>
            <Navigation/>
            <Switch>
              <Route exact path="/" component={Main}/>
              <Route path="/projects" component={Projects}/>
              <Route path="/contact" component={Contact}/>
            </Switch>
            <Footer/>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
