import React from 'react';

export default function Main () {
    return (
      <div className="content">
        <h1>Hello!<br/>
        My name is Cameo<br/>
        I'm a developer
        </h1>
        <p>A Melbournian with a love for all things web development.</p>
        <p>And also dogs.</p>
      </div>
    );
}