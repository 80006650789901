import React from 'react';

export default function Footer () {
    return (
        <div className="footer">
                <div className="links">
                    <a href="https://www.linkedin.com/in/cameolangford/" target="_blank" rel="noopener noreferrer" className="hvr-grow links">LinkedIn</a>
                    <a href="https://github.com/cameocodes" target="_blank" rel="noopener noreferrer" className="hvr-grow links">GitHub</a>
                </div>
                <div></div>
        </div>
    );
}