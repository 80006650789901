import React from 'react';
import { Link } from 'react-router-dom';


export default function Navigation() {
    return (
        <div className="navigation">
            <Link className="links hvr-grow" to="/">Home</Link>
            <Link className="links hvr-grow" to="/projects">Projects</Link>
            <Link className="links hvr-grow" to="/contact">Contact</Link>
        </div>
    );
}